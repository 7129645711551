import axios from 'axios';
import { NextSeo, OrganizationJsonLd } from 'next-seo';
import { useRouter } from 'next/router';
import { GetStaticProps } from 'next/types';
import { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FAQTabData } from 'components/Landing/FAQ';
import i18n from 'config/i18n';
import { REACT_APP_FAQ_WP_API_ENDPOINT } from 'constants/constants';
import LandingContainer from 'containers/LandingContainer';
import { botUserAgent, canonicalLink, languageAlternatesLinks } from 'utils/seo';

import { templateCategoriesApi } from '../api/templateCategories';
import { DEFAULT_LOCALE } from 'constants/languages';

const CACHE_TTL_SEC = process.env.CACHE_HTML_PAGE_TTL_SEC ? +process.env.CACHE_HTML_PAGE_TTL_SEC : 60;

export const getStaticProps: GetStaticProps<ComponentProps<typeof LandingContainer>> = async (props) => {
  await i18n.changeLanguage(props.locale ?? DEFAULT_LOCALE);
  const [faqDataResponse, categoriesMap] = await Promise.all([
    axios.get(`${REACT_APP_FAQ_WP_API_ENDPOINT}${props.locale}`),
    templateCategoriesApi.getCategoriesMap(),
  ]);

  const faqData = (faqDataResponse.data || []) as FAQTabData[];

  return {
    props: { faqData, categoriesMap, locale: props.locale },
    ...(CACHE_TTL_SEC && { revalidate: CACHE_TTL_SEC }),
  };
};

export default function Landing(props: ComponentProps<typeof LandingContainer> & { locale: string }) {
  const [jsEnabled, setJsEnabled] = useState(false);
  const [isBot, setIsBot] = useState(false);
  const { t } = useTranslation();
  const { locales } = useRouter();

  useEffect(() => {
    setJsEnabled(true);
    setIsBot(botUserAgent.test(window.navigator.userAgent));
  }, []);

  return (
    <>
      <OrganizationJsonLd
        type="Organization"
        id="https://myqrcode.com/#organization"
        logo="https://myqrcode.com/myqrcode-logo.svg"
        image="https://myqrcode.com/myqrcode-logo.svg"
        name={t('qr.page.landing.seo.company.name', 'MyQRCode')}
        address={{
          type: 'PostalAddress',
          streetAddress: t('qr.page.landing.seo.company.street', '46, Akademik Stefan Mladenov Str., Office 5'),
          addressLocality: t('qr.page.landing.seo.company.city', 'Sofia'),
          addressRegion: t('qr.page.landing.seo.company.region', 'Sofia'),
          postalCode: t('qr.page.landing.seo.company.postal', '1700'),
          addressCountry: t('qr.page.landing.seo.company.country', 'BG'),
        }}
        url="https://myqrcode.com"
        telephone={t('qr.page.landing.seo.company.telephone', '+1-631-892-9925')}
        email={t('qr.page.landing.seo.company.email', 'support@myqrcode.com')}
      />
      <NextSeo
        title={t('qr.page.landing.seo.title', 'QR Code Generator:  Turn any link, vCard or file into a QR code')}
        description={t(
          'qr.page.landing.seo.description',
          'QR Code Generator: convert any link, vCard, facebook, image or video into a QR code, create QR restaurant menu, track QR code scans, customize design'
        )}
        languageAlternates={languageAlternatesLinks('', locales)}
        canonical={canonicalLink('', false, i18n.language)}
      />
      <LandingContainer {...props} expandAllFAQ={!jsEnabled || isBot} />
    </>
  );
}
